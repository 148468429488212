@import url('https://fonts.cdnfonts.com/css/tw-cen-mt-std');
@import url('https://fonts.cdnfonts.com/css/tw-cen-mt');
@import 'bootstrap/dist/css/bootstrap.css';

/* Global Rules*/

.header {
    font-size: 2.3rem;
    font-weight: 600;
}


/* Background */
.popup {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 9;
    left: 0;
}

.popup-inner {
    position: relative;
    width: 45%;
    margin: 0 auto;
    height: auto;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}


.close-btn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: none;
    background: rgba(0, 0, 0, 0);
    font-size: 25px;
}

.testImage2 {
    background-image: url("../../images/login-image.jpg");
    padding-top: 6rem;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.registerBG {
    background-color: rgb(255, 244, 218);
}
  

/* Google Sign In Styles */
.google-sign-in-button {
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
        
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}

.google-sign-in-button:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button:active {
    background-color: #eeeeee;
}

.google-sign-in-button:active {
    outline: none;
    box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.google-sign-in-button:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}

.disabled {
    opacity: 0.42 !important;
    cursor: not-allowed;
    border: 2px solid rgba(169, 169, 169, 0.295);
}

.form-control {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
}

.errorMessage {
    background-color: rgba(256, 0, 0, .3);
    padding: 8px;
    margin: 5px;
}
