
.card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    background-color: #f8f9fa;
    border-bottom: none;
}


/*----------------Shopping Cart Section Styling----------------*/

.shoppingCartItem {
    padding-bottom: 1.4rem;
}

.checkoutWrapper {
    width: 53vw;
}

/*----------------Shipping Section Styling----------------*/

.form-outline {
    position: relative;
    margin-bottom: 24px;
}

.form-label {
    position: absolute;
    top: -6px;
    left: 15px;
    background-color: #fff;
    padding: 0 5px;
    font-size: 12px;
    color: #9e9e9e;
}

.form-control {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 16px;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.disabled {
    opacity: 0.42 !important;
    cursor: not-allowed;
    border: 2px solid rgba(169, 169, 169, 0.295);
}
