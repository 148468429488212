
.materialIcon {
    color: rgb(70, 70, 70);
}

.scrollableList {
    max-height: 100%;
    overflow-x: hidden;
    width: 33vw;
}

.cartHeader {
    height: 10vh;
    width: 100%;
}

.accordionSummary {
    width: 100%;
}

.subheader {
    width: max-content;
}

.button-dark-checkout {
    border: normal;
    border-radius: 12px;
    border: 2px solid #5a5a5a;
    color: #5a5a5a;
    padding-left: 7rem;
    padding-right: 7rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    text-align: center;
    transition-duration: 0.3s;
}

.button-dark-checkout:hover {
  color: rgb(58, 58, 58);
  background: rgba(133, 133, 133, 0.1);
  border-color: rgb(58, 58, 58);
}



/*-------------------------- Samsung S21 Viewport(360x800) --------------------------*/
@media screen and (max-width: 820px) {
    
    .scrollableList {
        max-height: 100%;
        overflow: 'auto';
        width: 103vw;
    }


}
