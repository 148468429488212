
.materialIcon {
    color: rgb(70, 70, 70);
}

.active {
    border: 2px solid rgba(24, 24, 24, 0.733);
    background: rgba(17, 17, 17, 0.336) !important;
}

.m-n1 {
    margin-right: -0.3rem !important;
    margin-left: -0.3rem !important;
}

.tab {
    margin-right: 0rem;
    margin-left: 0rem;
}

/*----------------Image Section Styling----------------*/

.imageUploadBar {
    width: 60%;
}

/*----------------Frame Section Styling----------------*/

.frame-icons {
    width: 2.9vw;
    height: 2.9vw;
}

.frame-grid  {
    border: 2px solid rgba(158, 158, 158, 0.637);
    border-radius: 8px;
}

.frame-grid:hover {
    border: 2px solid rgba(35, 35, 35, 0.937);
    background: rgba(81, 81, 81, 0.278);
}

.frame-grid:active {
    border: 2px solid rgba(11, 11, 11, 0.945);
    background: rgba(0, 0, 0, 0.911) !important;
}

.frame-grid:disabled {
    opacity: 0.42;
    cursor: not-allowed;
    border: 2px solid rgba(169, 169, 169, 0.295);
}


/*----------------Matboard Section Styling----------------*/

.circle {
    display: flex;
    width: 3vw;
    height: 3vw;
    background-color: green;
    border-radius: 50%;
}

.matboardFormLength {
    width: 55%;
}

.matboardFormLength-Number {
    width: 30%;
}


/*----------------Accessories Section Styling----------------*/

.hangingStyleCloseupImage {
    width: 2rem;
    height: 2rem;
}

.hangingStyleFullImage {
    width: 20vw;
    height: 20vw;
}


/*----------------Tool Preview Styling----------------*/

.toolPreviewVisTool {
    width: 30vw;
}


/*-------------------------- Samsung S21 Viewport(360x800) --------------------------*/
@media screen and (max-width: 820px) {

    .m-n1 {
        margin-right: -0.3rem !important;
        margin-left: -0.3rem !important;
    }

    /*----------------Image Section Styling----------------*/

    .imageUploadBar {
        width: 98%;
    }

    /*----------------Frame Section Styling----------------*/
        
    .frame-icons {
        width: 10vw;
        height: 10vw;
    }

    .circle {
        display: flex;
        width: 3vw;
        height: 3vw;
        background-color: green;
        border-radius: 50%;
    }

    .frame-grid  {
        border: 2px solid rgba(158, 158, 158, 0.637);
        border-radius: 8px;
    }

    .frame-grid:hover {
        border: 2px solid rgba(35, 35, 35, 0.937);
        background: rgba(81, 81, 81, 0.278);
    }

    .frame-grid:active {
        border: 2px solid rgba(11, 11, 11, 0.945);
        background: rgba(0, 0, 0, 0.911) !important;
    }

    .frame-grid:disabled {
        opacity: 0.42;
        cursor: not-allowed;
        border: 2px solid rgba(169, 169, 169, 0.295);
    }

    .active {
        border: 2px solid rgba(24, 24, 24, 0.733);
        background: rgba(17, 17, 17, 0.336) !important;
    }

    /*----------------Matboard Section Styling----------------*/

    .matboardFormLength {
        width: 100%;
    }

    .matboardFormLength-Number {
        width: 65%;
    }

    /*----------------Accessories Section Styling----------------*/

    .hangingStyleCloseupImage {
        width: 1rem;
        height: 1rem;
    }

    .hangingStyleFullImage {
        width: 12vw;
        height: 12vw;
    }

    /*----------------Tool Preview Styling----------------*/

    .toolPreviewVisTool {
        width: 90vw;
    }

}
